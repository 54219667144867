<template>
  <div class="ld-dismissable-banners">
    <ld-dismissable-banner
      v-for="banner in banners"
      :key="banner.key"
      :text="banner.description"
      :feature="banner.feature"
      :upgradeText="banner.action"
      :customNumberText="banner.customNumberAction"
      :upgradeLink="upgradeLink"
      :customNumberLink="customNumberLink"
      :error="banner.error"
      :featureMeteredUsageKey="featureMeteredUsageKey"
      @input="onDismiss(banner.feature, banner.threshold, banner.key)"
      @accept="acceptMeteredUsage(banner.feature, banner.key)"
    ></ld-dismissable-banner>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import dateFns from 'date-fns';
import LimitsContent from 'lion-vue-src/config/limitsContent';
import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';
import LdThresholds from 'lion-vue-src/config/thresholds';
import LdOveragePricing from 'lion-vue-src/config/overagePricing';
import featureGate from 'lion-vue-src/Shared/mixins/featureGate';
import LdPlans, { mlsPlans } from 'lion-vue-src/config/plans';
import LdDismissableBanner from './LdDismissableBanner';

const LIMIT_WARNINGS_KEY = 'limit_warnings';
const PURCHASE_NUMBER_TEXT = 'Purchase Number';
const SELECT_NUMBER_TEXT = 'Select Number';

const FREE_NUMBER_PLANS = ['pro+', 'elite'];

const getBanner = (feature, user) => {
  if (!user || !user.features || !user.features[feature]) {
    return;
  }

  const { tracking } = user.features[feature];
  if (!tracking) {
    return;
  }
  const threshold = tracking.limit_usage_warning;

  const content = LimitsContent[feature];
  if (!content) {
    return;
  }

  const userHasCustomNumber = !!user.custom_number;
  const usage = tracking.limit ? `${tracking.limit - tracking.remaining}/${tracking.limit}` : 'all';
  const resetType = tracking.reset_type;
  const lastReset = tracking.last_reset_at.Time;
  const nextReset = tracking.next_reset_at.Time;

  if (!threshold) {
    return;
  }

  let plan = user.subscriptionOverview.txt_plan;
  if (mlsPlans.includes(plan)) {
    plan = 'mls';
  }

  // get limits content for all plans/thresholds
  const defaultItem = content.Default.Default;
  // get limits content for specific threshold and all plans
  const defaultThresholdItem = content.Default[threshold] ? content.Default[threshold] : {};
  // get limits content for specific plan and all thresholds
  const defaultPlanItem = content[plan] ? content[plan].Default : {};
  // get limits content for specific plan and specific threshold
  const planThresholdItem =
    content[plan] && content[plan][threshold] ? content[plan][threshold] : {};

  // merge above objects in order of increasing specificity
  const banner = Object.assign(
    {},
    { feature, threshold, lastReset, key: `${feature}${Math.random()}` },
    defaultItem,
    defaultThresholdItem,
    defaultPlanItem,
    planThresholdItem,
  );

  banner.remaining = tracking.remaining;
  if (banner.customNumberDescription && !userHasCustomNumber) {
    banner.customNumberAction = FREE_NUMBER_PLANS.includes(plan)
      ? SELECT_NUMBER_TEXT
      : PURCHASE_NUMBER_TEXT;
    banner.description = banner.customNumberDescription;
  }

  // fill in usage and next reset
  banner.description = banner.description.replace('USAGE_INFO', usage);

  let resetMessage = `Your limit will be reset on ${dateFns.format(nextReset, 'MMM D, YYYY')}.`;

  if (plan === LdPlans.PAB) {
    resetMessage = `Your credits will renew on ${dateFns.format(
          nextReset,
          'MMM D, YYYY',
        )}.`;
  }

  banner.description = banner.description.replace(
    'RESET_MESSAGE',
    resetType === 'monthly' ? resetMessage : '',
  );

  banner.description = banner.description.replace(
    'OVERAGE_PRICING',
    `<strong>${LdOveragePricing[feature][plan]}</strong>`,
  );

  return banner;
};

const getSettings = () => {
  return JSON.parse(localStorage.getItem(LIMIT_WARNINGS_KEY)) || {};
};

const setSettings = settings => {
  localStorage.setItem(LIMIT_WARNINGS_KEY, JSON.stringify(settings));
};

const shouldShow = (feature, user, threshold, lastReset, remaining, settings) => {
  const featureSettings = Object.assign(
    {},
    { threshold: LdThresholds.Low, dismissed: new Date(0).toJSON() },
    settings[feature],
  );

  let plan = user.subscriptionOverview.txt_plan;
  if (plan === LdPlans.PAB && remaining > 0) {
    return false;
  }

  // if banner was dismissed before last reset, show regardless of threshold
  // if banner was dismissed after last reset, show if current threshold is higher than last
  if (featureSettings.dismissed < lastReset) {
    return true;
  }
  // Threshold weight is determined by its order in LdThresholds object
  // using findIndex of the LdThresholds object keys array
  const lastThresholdWeight = Object.keys(LdThresholds).findIndex(item => {
    return LdThresholds[item] === featureSettings.threshold;
  });
  const thisThresholdWeight = Object.keys(LdThresholds).findIndex(item => {
    return LdThresholds[item] === threshold;
  });
  return thisThresholdWeight > lastThresholdWeight;
};

const {
  mapActions: mapSubscriptionActions,
  mapState: mapSubscriptionState,
} = createNamespacedHelpers('subscription');

export default {
  components: { LdDismissableBanner },
  props: {
    user: {
      type: Object,
      default: null,
    },
    upgradeLink: {
      type: String,
    },
    customNumberLink: {
      type: String,
    },
    featureMeteredUsageKey: {
      type: String,
      default: null,
    },
  },
  methods: {
    onDismiss(feature, threshold, key) {
      const index = this.banners.findIndex(item => item.key === key);
      this.banners.splice(index, 1);
      const settings = getSettings();
      setSettings(Object.assign({}, settings, { [feature]: { threshold, dismissed: new Date() } }));
      this.$emit('toggle', false);
    },
    acceptMeteredUsage(feature, key) {
      this.bannerFeature = feature;
      this.bannerIndex = this.banners.findIndex(item => item.key === key);

      this.meteredUsageKey = `gated-feature-activator-${new Date().getTime()}`;
      this.toggleFeatureGate({ feature, meteredUsageKey: this.meteredUsageKey });
    },
    buildBanners(user) {
      const settings = getSettings();
      this.bannerFeatures.forEach(feature => {
        const banner = getBanner(feature, user);
        if (banner && shouldShow(feature, user, banner.threshold, banner.lastReset, banner.remaining, settings)) {
          // add banner if not already present for given feature
          if (!this.banners.find(b => b.feature === feature)) {
            this.banners.push(banner);
            this.$emit('toggle', true);
          }
        }
      });
    },
  },
  data() {
    return {
      bannerFeatures: [
        LdFeatures.Emails,
        LdFeatures.TextMessaging,
        LdFeatures.VideoTexts,
        LdFeatures.LeadAssist,
      ],
      banners: [],
    };
  },
  watch: {
    user: {
      handler(value) {
        if (!value) {
          return;
        }
        this.buildBanners(value);
      },
      immediate: true,
      deep: true,
    },
    addonPurchaseRequested(value) {
      if (!value) {
        this.banners = [];
        this.buildBanners(this.user);
      }
    },
  },
  computed: {
    ...mapSubscriptionState(['addonPurchaseRequested']),
  },
};
</script>
<style lang="scss">
.ld-dismissable-banners {
  width: 100%;
}
</style>

